import './Techstacks.css';

import React from 'react';
import { DiCss3, DiGoogleCloudPlatform, DiMongodb } from 'react-icons/di';
import { FaAws, FaNodeJs, FaReact } from 'react-icons/fa';
import {
  SiGraphql,
  SiMaterialui,
  SiPostgresql,
  SiQuarkus,
  SiRedux,
  SiSpringboot,
  SiStorybook,
  SiTypescript,
} from 'react-icons/si';

export const Techstacks = () => {
  return (
    <>
      <div className="section main" data-aos="fade-right">
        <h2 className="section__title different">Skills</h2>
        <div className="techsection">
          <div>
            <SiTypescript />
            <h5>TypeScript</h5>
          </div>
          <div>
            <DiGoogleCloudPlatform />
            <h5>GCP</h5>
          </div>
          <div>
            <FaReact />
            <h5>React</h5>
          </div>
          <div>
            <FaNodeJs />
            <h5>Nodejs</h5>
          </div>
          <div>
            <SiQuarkus />
            <h5>Quarkus</h5>
          </div>

          <div>
            <SiSpringboot />
            <h5>Springboot</h5>
          </div>

          <div>
            <FaAws />
            <h5>AWS</h5>
          </div>
          <div>
            <SiPostgresql />
            <h5>Postgresql</h5>
          </div>
          <div>
            <SiGraphql />
            <h5>Graphql</h5>
          </div>
          <div>
            <SiMaterialui />
            <h5>Material UI</h5>
          </div>
          <div>
            <SiRedux />
            <h5>Redux</h5>
          </div>
          <div>
            <SiStorybook />
            <h5>Storybook</h5>
          </div>
        </div>
      </div>
    </>
  );
};
