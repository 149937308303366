import './About.css';

import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Introduction } from './Introduction';
import { Techstacks } from './Techstacks';
import { Timeline } from './Timeline';
import { Type } from './Type';

export const About = () => {
  return (
    <>
      <div className="about center">
        <h1 data-aos="fade-right" className="mobileHead">
          Hi, I'm <span className="about__name">Louise Connell</span>
        </h1>
        <Type />
        <p className="about__desc" data-aos="fade-right">
          Experienced Full Stack Developer with a with a passion for learning
          and writing clean, maintainable code. I am a self-motivated,
          enthusiastic and hardworking individual with a keen interest in cloud
          technologies and DevOps. I am currently working as a Junior Developer
          at{' '}
          <a
            className="link"
            href="https://www.olmsystems.com/"
            target="_blank"
            rel="noreferrer"
          >
            OLM Systems
          </a>
          .
        </p>
        <div className="about__contact center">
          <a
            href="https://github.com/Connell-L"
            aria-label="github"
            target="_blank"
            rel="noreferrer"
            className="link link--icon"
          >
            <GitHubIcon />
          </a>
          <a
            href="mailto:louiseconnell77@outlook.com"
            target="_blank"
            rel="noreferrer"
            aria-label="mail"
            className="link link--icon"
          >
            <EmailIcon />
          </a>
          <a
            href="tel:+447470927925"
            target="_blank"
            rel="noreferrer"
            aria-label="phone"
            className="link link--icon"
          >
            <PhoneIcon />
          </a>
          <a
            href="https://www.linkedin.com/in/louise-connell-b0677721b/"
            aria-label="linkedin"
            className="link link--icon"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://twitter.com/Connell_L_tech"
            aria-label="twitter"
            className="link link--icon"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>

        <button
          className="btnResume"
          onClick={() => {
            window.open(
              'https://docs.google.com/document/d/1d4MQ7B1CA2vsPSc4cMX0dSehW1Yh18jD/edit?usp=sharing&ouid=115954908016174474727&rtpof=true&sd=true'
            );
          }}
        >
          Resume
        </button>
      </div>
      <Introduction />
      <Timeline />
      <section id="#skills">
        <Techstacks />
      </section>
    </>
  );
};
