import React from 'react';
import Typewriter from 'typewriter-effect';

export const Type = () => {
  return (
    <>
      <Typewriter
        options={{
          strings: ['Full Stack Developer', 'Cloud Enthusiast', 'Blogger'],
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
        }}
      />
    </>
  );
};
