import './Projects.css';

import React from 'react';
import { SiReact, SiTypescript, SiWebpack } from 'react-icons/si';

export const Projects = () => {
  return (
    <>
      <div className="section">
        <h2 className="section__title different">Projects</h2>
        <div className="allProjects">
          <div className="projects_container" data-aos="fade-right">
            <div className="project">
              <div className="project_videocontainer">
                <div>
                  <img
                    src="https://ms314006.github.io/static/b7a8f321b0bbc07ca9b9d22a7a505ed5/97b31/React.jpg"
                    alt="Discord Bot"
                  />
                </div>
              </div>
              <div className="project_information">
                <h2>React TypeScript Template</h2>
                <p>
                  A template for creating a React TypeScript project with
                  Webpack, Babel, ESLint, Prettier and Jest. It will bootstrap a
                  new project with a custom webpack configuation and testing
                  suite.
                </p>
                <div>
                  <SiReact />
                  <SiTypescript />
                  <SiWebpack />
                </div>
                <div>
                  {/* <a href="#" target="_blank" rel="noreferrer">
                    <span type="button" className="btns onbt onbt">
                      Demo
                    </span>
                  </a> */}
                  <a
                    href="https://github.com/Connell-L/typescript-react-template"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span type="button" className="btns onbt">
                      View Code
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
