import './Introduction.css';

import React from 'react';
import { ThemeContext } from '../../Context/theme';
import profilePic from '../../assets/louiseconnell.png';

export const Introduction = () => {
  const [{ themename }] = React.useContext(ThemeContext);

  return (
    <>
      <section id="#about">
        <div className="section" data-aos="fade-right">
          <h2 className="section__title">
            About <span className="different">Me</span>
          </h2>
          <div className={'introduction ' + themename}>
            <div className="introduction_logocontainer">
              <img src={profilePic} alt="Images" />
            </div>
            <div className="introduction_datacontainer">
              <h4>
                Hello, I'm <span className="different">Louise Connell</span>from{' '}
                <span className="different">Scotland</span>, holding a BA (Hons.
                Psychology) degree from the{' '}
                <span className="different">University of Stirling</span>. I
                moved into programming through self-learning and bootcamps.
                Currently a Junior Developer at{' '}
                <span className="different">OLM Systems</span> for two years
              </h4>

              <h4 className="introduction_text">
                Particular interests include project architecture, I enjoy
                findings ways to automate processes and speed up development
                time. I'm also interested in cloud technologies and DevOps. I
                have <span className="different">AWS Cloud Practicioner</span>{' '}
                certification and I'm currently working towards AWS Solutions
                Architect. I have working knowledge of GCP and to a lesser
                extent, Azure.
              </h4>

              <h4 className="introduction_text">
                I consider myself to be a 'T' shaped developer, I specialize in
                React-based microfrontends but have a lot of experience in
                developing Quarkus/Spring Boot-based microservices. While not
                actively job hunting, I'm keen on exploring collaboration
                opportunities, especially in{' '}
                <span className="different">open source</span> projects.
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
